import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../../components/seo'
import NewsSectionBlock from '../../components/NewsSectionBlock'
import Typography from '../../components/Common/Typography'
import List from '../../components/Common/List'

import * as s from '../../pages/news.module.scss'

const DevDigestQ22024 = () => {
  return (
    <>
      <SEO
        title="Новости разработки продуктов SIGNAL за 2 квартал 2024"
        description="Собрали все обновления, которые вышли за 2 квартал 2024 в модулях среды общих данных, аналитики и плагинах для Revit и Navisworks"
      />
      <NewsSectionBlock date="03.07.2024">
        <Typography variant="h1" color="blue">
          Квартальные обновления SIGNAL | Q2 2024
        </Typography>
        <Typography variant="h2" color="blue">
          SIGNAL TOOLS
        </Typography>
        <Typography variant="body1">
          Мы окончательно завершили разработку модуля SIGNAL TOOLS, весь
          функционал и задачи, которые ставили перед собой, реализованы:
        </Typography>
        <List>
          <li>Проверка BIM-модели на заполненность параметров;</li>
          <li>
            Генерация поисковых наборов по значениям параметров, правилам
            описанным в Excel или классификатору в txt;
          </li>
          <li>Выгрузка ведомости объемов работ из модели;</li>
          <li>Генерация Бюджета строительства по модели;</li>
          <li>Связка с календарным графиком BIM 4D;</li>
          <li>
            Ведение строительной BIM-модели:
            <List>
              <li>Отметка выполнения работ по модели;</li>
              <li>Привязка предписаний к элементам модели;</li>
              <li>Прикрепление документов ИД к элементам модели.</li>
            </List>
          </li>
          <li>Генерация КС-2 по отметке выполнения из BIM-модели.</li>
        </List>
        <Typography variant="h2" color="blue">
          SIGNAL DOCS
        </Typography>
        <ol className={cn(s.textLists, s.decimal)}>
          <li>
            <Typography variant="body1">
              В Замечаниях к PDF-документации добавили маркап "Точка";
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-1.png"
                alt='SIGNAL DOCS маркап "Точка" в замечаниях к PDF-документации'
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              Доработали функцию скачивания файлов: Теперь можно скачивать
              несколько папок и файлов. А также в расшаренных ссылках можно
              скачать файлы/папки по одиночке или сразу несколько;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-2.png"
                alt="SIGNAL DOCS скачивания файлов"
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              У завершенного процесса Согласования оставили кнопку
              "Перезапустить", которая перезапускает согласование в том же
              комплекте с возможностью добавить/удалить/обновить файлы;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-3.png"
                alt="SIGNAL DOCS завершенный процесс Согласования"
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              Добавили возможность просмотра, какие документы были в комплекте
              предыдущего согласования, нажатием на "Галочку" завершенного
              согласования;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-4.png"
                alt="SIGNAL DOCS просмотра документов в комплекте предыдущего согласования"
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              Завершить согласование на финальном этапе теперь можно "с
              замечаниями". Таким образом, галочка подсветиться желтым,
              валидатор QR станет желтым и получит статус "Завершено с
              замечаниями" с комментарием о замечании;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-5.png"
                alt="SIGNAL DOCS завершение согласования на финальном этапе с замечаниями"
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              Все PDF теперь открываются в постраничном режиме;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              В реестр Замечаний добавили столбец "Кем добавлено";
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Добавили возможность прикреплять несколько файлов к Замечаниям;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Поле "Плановая дата" в Согласованиях теперь не обязательное;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Добавили пакетное изменение Согласований;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Добавили пакетное редактирование замечаний;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-6.png"
                alt="SIGNAL DOCS пакетное редактирование замечаний"
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              Добавили возможность создать Комплект на Согласования
              непосредственно из "Документов";
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-7.png"
                alt='SIGNAL DOCS Комплект на Согласования непосредственно из "Документов"'
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              Добавили привязку элементов модели (rvt, nwd, nwc и ifc) к
              замечанию;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-8.png"
                alt="SIGNAL DOCS привязка элементов модели (rvt, nwd, nwc и ifc) к замечанию"
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              Добавили импорт Комплектов согласований из Excel;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-9.png"
                alt="SIGNAL DOCS импорт Комплектов согласований из Excel"
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              Добавили просмотр графиков формата MPP (из MS Project);
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-10.png"
                alt="SIGNAL DOCS просмотр графиков формата MPP (из MS Project)"
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">Добавили сравнение в DWG;</Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-11.png"
                alt="SIGNAL DOCS сравнение в DWG"
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              Добавили поиск при выборе проектов и в целом причесали кнопочки и
              шрифты в дизайне;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-12.png"
                alt="SIGNAL DOCS поиск при выборе проектов"
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              Добавили переключение PDF в пределах папки;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-13.png"
                alt="SIGNAL DOCS переключение PDF в пределах папки"
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              Добавили возможность прикреплять вложения в замечаниях с ПК и
              телефона, а также прикреплять ссылки на внешние источники;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-14.png"
                alt="SIGNAL DOCS прикреплять вложения в замечаниях с ПК и телефона"
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              Добавили в обозначение версий дату загрузки версии;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-15.png"
                alt="SIGNAL DOCS дата загрузки версии в обозначение версий"
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              Увеличили количество статусов замечания: "На рассмотрении" стал "В
              работе". Добавили "На проверке" и "Отклонено". "Удалено" стал
              "Снято"
            </Typography>
            <Typography variant="body1">В итоге:</Typography>
            <List>
              <li>Черновик [Draft] (серый);</li>
              <li>Открыто [Open] (оранжевый);</li>
              <li>В работе [In progress] (синий);</li>
              <li>На проверке [In review] (фиолетовый);</li>
              <li>Закрыто [Closed] (зеленый);</li>
              <li>Отклонено [Rejected] (красный);</li>
              <li>Снято [Void] (Серый). - бывшее Удалено</li>
            </List>
            <Typography variant="body1">
              Ответственному доступны статусы:
            </Typography>
            <List>
              <li>Открыто;</li>
              <li>На проверке;</li>
              <li>В работе;</li>
              <li>Отклонено;</li>
            </List>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-16.png"
                alt="SIGNAL DOCS статусы"
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              Добавили "Замечания" в Согласования. Теперь в согласованиях
              отображаются замечания из файлов, которые были добавлены в
              Комплект;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-17.png"
                alt="SIGNAL DOCS Замечания в Согласования"
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              Расширили количество ответственных на этапах Согласования до
              20-ти;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Добавили возможность загрузки версии файла через контекстное меню.
              При этом, если файл на компе имеет другое имя, то он примет имя
              того файла, в который загружается;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={512}
                src="../../assets/images/news/dev-digest-Q2-2024/image-18.png"
                alt="SIGNAL DOCS загрузки версии файла через контекстное меню"
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              Добавили галочку "Запустить процесс согласования" при добавлении
              файлов в комплект;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-19.png"
                alt='SIGNAL DOCS галочка "Запустить процесс согласования" при добавлении файлов в комплект'
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              В комплекте Согласования теперь можно перейти в папку файла,
              щелкнув на него ПКМ и нажав "Открыть папку";
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              В сравнении PDF добавили возможность поворота листа;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              В Замечаниях, выбранный в списке маркап теперь подсвечивается;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Добавили переход между PDF в расшаренных папках;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Добавили выбор листа/вида в сравнении моделей. Можно выбрать
              сравнение 3D видов или 2D листов;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-20.png"
                alt="SIGNAL DOCS выбор листа/вида в сравнении моделей"
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              Добавили экспорт комплектов согласований с файлами. Выгружаются
              файлы с статусами по каждому этапу;
            </Typography>
          </li>
        </ol>
        <Typography variant="h2" color="blue">
          SIGNAL DASHBOARD
        </Typography>
        <ol className={cn(s.textLists, s.decimal)}>
          <li>
            <Typography variant="body1">
              В DASHBOARD добавили карточку "35_График по MPP". График
              импортируется из файла формата MPP (MS_Project), расположенного в
              DOCS;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-21.png"
                alt='SIGNAL DASHBOARD карточка "35_График по MPP"'
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              Добавили карточку "35_График по MPP с Вехами". Отличие видно по
              названию - можно отмечать работы, которые будут отображаться
              вехами, в которые можно проваливаться до графика;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-22.png"
                alt='SIGNAL DASHBOARD карточка "35_График по MPP с Вехами"'
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              Поменялись номера и названия карточек;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-23.png"
                alt="SIGNAL DASHBOARD название карточек"
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              DASHBOARD. Добавили 3 карточки по согласованиям:
            </Typography>
            <List>
              <li>63_ОБЩАЯ ПО СОГЛАСОВАНИЯМ В DOCS</li>
              <li>64_ДИНАМИКА СОГЛАСОВАНИЙ В DOCS</li>
              <li>65_РЕЕСТР СОГЛАСОВАНИЙ В DOCS</li>
            </List>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-24.png"
                alt="SIGNAL DASHBOARD карточки по согласованиям"
                placeholder="blurred"
              />
            </div>
          </li>
        </ol>
        <Typography variant="h2" color="blue">
          SIGNAL HUB
        </Typography>
        <ol className={cn(s.textLists, s.decimal)}>
          <li>
            <Typography variant="body1">
              Добавили экспорт в Эксель информации по пользователям Компании,
              включая пользователей и роли в Проектах;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-25.png"
                alt="SIGNAL HUB экспорт в Эксель информации по пользователям компании"
                placeholder="blurred"
              />
            </div>
          </li>
          <li>
            <Typography variant="body1">
              На Главную добавили карточки с ссылками на все наши ресурсы;
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../../assets/images/news/dev-digest-Q2-2024/image-26.png"
                alt="SIGNAL HUB карточки с ссылками на все наши ресурсы"
                placeholder="blurred"
              />
            </div>
          </li>
        </ol>
      </NewsSectionBlock>
    </>
  )
}

export default DevDigestQ22024
